import React from 'react';


const Popup = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        {title && 
          <>
            <h2 className="popup-header mb-4 mt-2 w-100 ">{title}</h2>
            <button className="close-btn mb-4 mt-2" onClick={onClose}>
              &times;
          </button>
          </>
        }
        <div className="popup-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;