import moment from "moment";
import url from "../../constants/url";

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}
export function formatTime(time) {
  return moment(time).format("h:mm A");
}
export function formatDate2(data) {
  return moment(data).format("D MMM YYYY");
}

export function shareLink(gameId, gameType, gameTypeName,id, type) {
  //https://kgenstage.page.link/?link=https://kgen.io/gamer/gamerji?pathName=<gameId=123?tournament=abc?contestId=123>&apn=com.indiggcommunity&isi=6478902511&ibi=com.indiggcommunity.ios
  var link = '';
  if (type === "contest") {
    // link = `gameId=${gameId}?gameTypeId=${gameType}?gameTypeName=${gameTypeName}?contestId=${id}?type=contest`
    link = `gameId=${gameId}?gameTypeId=${gameType}?gameTypeName=${gameTypeName}?type=contest`
    link = url.deepLink(link);
    return {
      text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
      url: `${link}`,
    };
  } else {
    // link = `gameId=${gameId}?tournament=${id}?type=tournament`
     link = `gameId=${gameId}?gameTypeId=${id}?type=tournament`
    link = url.deepLink(link);
    return {
      text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
      url: `${link}`,
    };
  }
  return "";
}
