const url = {
    saas_api_url: 'https://indigg-api-by-eaas-staging.gamerji.cloud/api/',
    indigg_api_url: 'https://indigg-api-staging.gamerji.cloud/api/',
    klash_redirect_url: 'https://kgn.live/gamer/klash',
    image_url: 'https://gamerji-uploads.s3.amazonaws.com/',
    redirect_url: 'https://indigg-pwa-staging.gamerji.cloud/',
    deepLink: (link) => `https://kgn.live/gamer/klash?showLoginModal=true?${link}`,
    trackerUrl: (eventId) => `https://stage-platformbase-apigw.indi.gg/gamerji-assist/events/${eventId}/drop`
}
//add comment and build

export default url;