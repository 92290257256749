import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Radio,
  FormControlLabel,
  Divider,
  RadioGroup,
  Tabs,
  Tab,
} from "@mui/material";
import Header from "../../Component/Home/header";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import { TextField } from "@mui/material";
import Popup from "../Popup/basepopup";
import { useNavigate } from "react-router";
import { getInGameNameByIdentifier, createTeam, getTeam, updateTeam, updateInGameNameByIdentifier, onCreateTournament, joinContestApi } from "../../apis"
import { Formik, Form, Field } from "formik";
import ToastPopup from "../Popup/ToastPopup"
import Loader from "../loader";
import { addTrackData, trackerEventId } from "../../config/tracker";
import url from "../../constants/url";
import { RWebShare } from "react-web-share";
import { shareLink } from "../Utilities/helper";

const Teamregistration = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    // navigate(-1)
    if (localStorage?.getItem("registerType") === "contest") {
      navigate(`/contest/list/${registerDataInfo?.game_type}`)
    }
    else {
      navigate("/tournament/list")
    }
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [captainIgn, setCaptainIgn] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [userDataInfo, setUserData] = useState("");
  const [teamName, setTeamName] = useState("");
  const [err, setErr] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [activeTab, setActiveTab] = useState({})
  const [captainInfo, setCaptainInfo] = useState({
    gamerjiName: "",
    countryCode: "",
    phoneNo: "",
    email: ""
  });

  let newArray = [];
  const [disabledCards, setDisabledCards] = useState([]);
  const [contestDisabled, setContestDisabled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [registerDataInfo, setRegisterData] = useState("");
  const [isLoading, setIsLoader] = useState(false);

  let noOfPlayer = JSON?.parse(localStorage?.getItem('registerData'))?.players

  for (let i = 0; i < noOfPlayer - 1; i++) {
    newArray.push({
      type: "phoneNo",
      countryCode: "",
      phoneNo: "",
      inNameGame: "",
      isSubmit: false,
      isOptional: false,
      userId: "",
      buttonStatus: 0,
      creationOrigin: "",
      isInGameNameExists: false,
      klashUserId: "",
    });
  }

  newArray.push({
    type: "phoneNo",
    countryCode: "",
    phoneNo: "",
    inNameGame: "",
    isSubmit: false,
    isOptional: true,
    userId: "",
    buttonStatus: 0,
    creationOrigin: "",
    isInGameNameExists: false,
    klashUserId: ""
  });

  const [frmInitialValues, setFrmInitialValues] = useState(newArray);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };


  useEffect(() => {
    let captainInfoLocal = JSON?.parse(localStorage?.getItem('profile'))
    let registerData = JSON?.parse(localStorage?.getItem('registerData'))
    if (!registerData) {
      navigate(-1)
    }
    setRegisterData(registerData);

    if (captainInfoLocal?.phoneNumber) {
      setCaptainInfo({
        inNameGame: captainInfoLocal?.userName,
        countryCode: captainInfoLocal?.countryCode,
        phoneNo: captainInfoLocal?.phoneNumber,
        userId: captainInfoLocal?.userId,
        user_id: captainInfoLocal?.user_id,
        type: "phoneNo"
      })
    }
    else {
      setCaptainInfo({
        inNameGame: captainInfoLocal?.userName,
        countryCode: "",
        phoneNo: captainInfoLocal?.email,
        userId: captainInfoLocal?.userId,
        user_id: captainInfoLocal?.user_id,
        type: "email"
      })
    }
    if (localStorage?.getItem("registerType") === "tournament") {
      addTrackData(trackerEventId?.Team_Registration_Shown, { gameCatalogName: `${localStorage.getItem("selectedGameName")}`, gameCatalogId: `${localStorage.getItem("game")}`, tournamentId: `${registerData?.event}` });
    } else {
      addTrackData(trackerEventId?.Team_Registration_Shown, { gameCatalogName: `${localStorage.getItem("selectedGameName")}`, gameCatalogId: `${localStorage.getItem("game")}`, contestId: `${registerData?.contest}` });
    }
  }, [])

  useEffect(() => {
    if (registerDataInfo?.contest) {
      localStorage?.removeItem('registerData')
    }
  }, [registerDataInfo])

  useEffect(() => {
    if (captainInfo?.phoneNo) {
      InGameNameApiCall()
      getTeamApiCall()
      let playerArray = [...frmInitialValues];
      let newArrayInfo = [];
      for (let i = 0; i < playerArray.length; i++) {
        playerArray[i].countryCode = captainInfo?.countryCode;
        newArrayInfo.push(playerArray[i]);
      }
      setFrmInitialValues(newArrayInfo);
    }
  }, [captainInfo])

  const InGameNameApiCall = async () => {
    setIsLoader(true);
    let payload = {
      "user_name": captainInfo?.phoneNo,
      "game": localStorage?.getItem("game")
    }

    if (captainInfo?.type === "phoneNo") {
      payload.user_name = captainInfo?.phoneNo;
      payload.countryCode = captainInfo?.countryCode;
      payload.type = "phoneNo"
    }
    else {
      payload.user_name = captainInfo?.phoneNo;
      payload.type = "email"
    }

    let gameNamesData = (await getInGameNameByIdentifier(payload))
    if (gameNamesData?.success) {
      setIsLoader(false);
      setCaptainIgn(gameNamesData?.data?.username || "")
    }
    else {
      setIsLoader(false);
      setCaptainIgn("")
    }
  }

  const getTeamApiCall = async () => {
    setIsLoader(true);
    let payload = {
      "game": localStorage?.getItem("game"),
      "gameType": registerDataInfo?.game_type,
      "_id": JSON?.parse(localStorage?.getItem('profile'))?._id
    }

    let getTeamRes = (await getTeam(payload))
    if (getTeamRes?.success) {
      setIsLoader(false);
      setTeamId(getTeamRes?.data?._id)
      setTeamName(getTeamRes?.data?.teamName?.trim())
      if (getTeamRes?.data?.team?.length > 0) {
        let playerArray = [...frmInitialValues];
        for (let i = 0; i < getTeamRes?.data?.team.length; i++) {
          playerArray[i].type = getTeamRes?.data?.team?.[i]?.userVia === "M" ? "phoneNo" : "email";
          playerArray[i].userId = getTeamRes?.data?.team?.[i]?.user_id;
          playerArray[i].phoneNo = getTeamRes?.data?.team?.[i]?.loginId;
          playerArray[i].countryCode = getTeamRes?.data?.team?.[i]?.countryCode;
          playerArray[i].inNameGame = getTeamRes?.data?.team?.[i]?.ign;
          playerArray[i].klashUserId = getTeamRes?.data?.team?.[i]?.klashUserId;
          if (getTeamRes?.data?.team?.[i]?.klashUserId) {
            // playerArray[i].isSubmit = true;
            playerArray[i].isInGameNameExists = true;
          }

          const cardId = `card-${i}`;
          setActiveTab((prevTabs) => ({
            ...prevTabs,
            [cardId]: getTeamRes?.data?.team?.[i]?.userVia === "M" ? "Mobile" : "Email",
          }));
        }

        setFrmInitialValues(playerArray);
      }
      else {
        setIsLoader(false);
      }
    }
    else {
      setIsLoader(false);
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleUsrBtn = async (index) => {
    setIsLoader(true);
    setErr('')
    setShowToast(false)
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };

    if (fromdataobj?.type == "email") {
      let res = validateEmail(fromdataobj?.phoneNo)

      if (!res) {
        setIsLoader(false);
        setErr("Please enter valid email");
        setShowToast(true)
        return
      }
    }
    if (fromdataobj?.type == "email" && fromdataobj?.email == "") {
      setIsLoader(false);
      setErr("Please enter valid email");
      setShowToast(true)
      return;
    }
    else if (fromdataobj?.phoneNo == "") {
      setIsLoader(false);
      setErr("Please enter the mobile number");
      setShowToast(true)
      return;
    }

    if (captainInfo?.email == fromdataobj?.phoneNo || captainInfo?.phoneNo == fromdataobj?.phoneNo) {
      setIsLoader(false);
      setErr('These are your details, please enter relevant details apart from yours');
      setShowToast(true)
      return;
    }

    let checkTeam = checkMobileWithTeam(fromdataobj?.phoneNo, index);
    if (!checkTeam) {
      let payload = {
        "game": localStorage?.getItem("game")
      }

      if (fromdataobj?.type === "phoneNo") {
        payload.user_name = fromdataobj?.phoneNo;
        payload.countryCode = fromdataobj?.countryCode;
        payload.type = "phoneNo"
      }
      else {
        payload.user_name = fromdataobj?.phoneNo;
        payload.type = "email"
      }

      getUserInGameNameData(payload, index);
    } else {
      setIsLoader(false);
      setErr("Already added this number");
      setShowToast(true)
      return;
    }
  };

  const getUserInGameNameData = async (data, index) => {
    setIsLoader(true);
    // setLoading(true)
    try {
      const res = await getInGameNameByIdentifier(data);
      if (res?.success) {
        // success
        // if (!checkUserIdWithTeam(res?.data?.user_id, index)) {
        let formdata = [...frmInitialValues];
        let fromdataobj = { ...formdata[index] };
        fromdataobj.inNameGame = res?.data?.username;
        fromdataobj.isSubmit = true;
        fromdataobj.userId = res?.data?.user_id;
        fromdataobj.creationOrigin = res?.data?.creationOrigin;
        fromdataobj.buttonStatus = 1;
        fromdataobj.isInGameNameExists = true;
        fromdataobj.klashUserId = res?.data?.userID;
        formdata[index] = fromdataobj;

        setFrmInitialValues(formdata);
        setDisabledCards(prevState => ([...prevState, index]));
        setIsLoader(false);
        return;
        // }
        // else {
        //   setErr("Already added this user");
        //   setShowToast(true)
        //   return;
        // }
      }
      else {
        if (res?.status === 422) {
          // In-game username not found for identifier
          // if (!checkUserIdWithTeam(res?.data?.errors?.[0]?.metaData?.user_id, index)) {
          let formdata = [...frmInitialValues];
          let fromdataobj = { ...formdata[index] };
          fromdataobj.inNameGame = fromdataobj?.inNameGame || "";
          fromdataobj.isSubmit = true;
          fromdataobj.userId = res?.data?.errors?.[0]?.metaData?.user_id;
          fromdataobj.klashUserId = res?.data?.errors?.[0]?.metaData?.userId;
          fromdataobj.creationOrigin = "";
          fromdataobj.buttonStatus = 1;
          fromdataobj.isInGameNameExists = false;
          formdata[index] = fromdataobj;
          setFrmInitialValues(formdata);
          setDisabledCards(prevState => ([...prevState, index]));

          setErr(res?.data?.errors?.[0]?.msg);
          setShowToast(true)
          setIsLoader(false);
          return;
          // }
          // else {
          //   setErr("Already added this user");
          //   setShowToast(true)
          //   return;
          // }
        }
        else if (res?.status === 500) {
          // Intermal server error

          let formdata = [...frmInitialValues];
          let fromdataobj = { ...formdata[index] };
          fromdataobj.inNameGame = "";
          fromdataobj.isSubmit = false;
          fromdataobj.userId = "";
          fromdataobj.creationOrigin = "";
          fromdataobj.buttonStatus = 0;
          fromdataobj.isInGameNameExists = false;
          fromdataobj.klashUserId = "";
          formdata[index] = fromdataobj;
          setFrmInitialValues(formdata);

          setErr(res?.data?.errors?.[0]?.msg);
          setShowToast(true)
          setIsLoader(false);
          return;
        }
        else if (res?.status === 404) {
          // Not found

          let formdata = [...frmInitialValues];
          let fromdataobj = { ...formdata[index] };
          fromdataobj.inNameGame = "";
          fromdataobj.isSubmit = false;
          fromdataobj.userId = "";
          fromdataobj.creationOrigin = "";
          fromdataobj.buttonStatus = 2;
          fromdataobj.isInGameNameExists = false;
          fromdataobj.klashUserId = "";
          formdata[index] = fromdataobj;
          setFrmInitialValues(formdata);

          setErr(res?.data?.errors?.[0]?.msg);
          setShowToast(true)
          setIsLoader(false);
          return;
        }
        else {
          //

          let formdata = [...frmInitialValues];
          let fromdataobj = { ...formdata[index] };
          fromdataobj.inNameGame = "";
          fromdataobj.isSubmit = false;
          fromdataobj.userId = "";
          fromdataobj.creationOrigin = "";
          fromdataobj.buttonStatus = 0;
          fromdataobj.isInGameNameExists = false;
          fromdataobj.klashUserId = "";
          formdata[index] = fromdataobj;
          setFrmInitialValues(formdata);

          setErr(res?.data?.errors?.[0]?.msg);
          setShowToast(true)
          setIsLoader(false);
          return;
        }
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error)
      // setLoading(false)

      // toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  let checkUserIdWithTeam = (user_id, ind) => {
    let index = frmInitialValues.findIndex(
      (obj, i) => obj?.userId == user_id && i !== ind
    );
    let indexCap = frmInitialValues.findIndex(
      (obj) => obj?.userId == captainInfo.user_id
    );

    if (index > -1 || indexCap > -1 || captainInfo.user_id === user_id) {
      return true;
    } else {
      return false;
    }
  };

  let checkMobileWithTeam = (number, ind) => {
    let index = frmInitialValues.findIndex(
      (obj, i) => obj?.phoneNo == number && i !== ind
    );
    let indexCap = frmInitialValues.findIndex(
      (obj) => obj?.phoneNo == captainInfo.phoneNo
    );
    if (index > -1 || indexCap > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleUsrBtnRemove = async (index) => {
    setErr('')
    setShowToast(false)
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };
    fromdataobj.phoneNo = "";
    fromdataobj.inNameGame = "";
    fromdataobj.isSubmit = false;
    fromdataobj.userId = "";
    fromdataobj.creationOrigin = "";
    fromdataobj.buttonStatus = 0;
    fromdataobj.isInGameNameExists = false;
    fromdataobj.klashUserId = "";
    formdata[index] = fromdataobj;
    setFrmInitialValues(formdata);
    setDisabledCards(prevState => (prevState.filter(
      (cardIndex) => cardIndex !== index
    )));
  };

  useEffect(() => {
    let isSubmitBtnValidate = true;

    if (!teamName?.trim()) {
      isSubmitBtnValidate = false
      return;
    }

    frmInitialValues.forEach((euser, index) => {
      index = index + 1;
      if (!euser?.isOptional) {
        if (!euser.phoneNo) {
          isSubmitBtnValidate = false
          return;
        }
        if (!euser.isSubmit) {
          isSubmitBtnValidate = false
          return;
        }
        if (!euser.inNameGame) {
          isSubmitBtnValidate = false
          return;
        }
      } else {
        if (euser.phoneNo != "") {
          if (!euser.phoneNo) {
            isSubmitBtnValidate = false
            return;
          }
          if (!euser.isSubmit) {
            isSubmitBtnValidate = false
            return;
          }
          if (!euser.inNameGame) {
            isSubmitBtnValidate = false
            return;
          }
        }
      }
    });

    setContestDisabled(isSubmitBtnValidate);
  }, [teamName, frmInitialValues])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    let errMsg = false;
    setContestDisabled(false)

    if (!teamName?.trim()) {
      setContestDisabled(true)
      setIsLoader(false);
      errMsg = true;
      setErr("Please enter team name");
      setShowToast(true)
      return;
    }

    frmInitialValues.forEach((euser, index) => {
      index = index + 1;
      if (!euser?.isOptional) {
        if (!euser.phoneNo) {
          errMsg = true;
          setContestDisabled(true)
          setIsLoader(false);
          setErr("Please enter the mobile number");
          setShowToast(true)
          return;
        }
        if (!euser.isSubmit) {
          errMsg = true;
          setContestDisabled(true)
          setIsLoader(false);
          setErr(`Please add user ${index + 1}`);
          setShowToast(true)
          return;
        }
        if (!euser.inNameGame) {
          errMsg = true;
          setContestDisabled(true)
          setIsLoader(false);
          setErr(`Please enter in game name for user ${index + 1}`);
          setShowToast(true)
          return;
        }
      } else {
        if (euser.phoneNo != "") {
          if (!euser.phoneNo) {
            errMsg = true;
            setContestDisabled(true)
            setIsLoader(false);
            setErr("Please enter the mobile number");
            setShowToast(true)
            return;
          }
          if (!euser.isSubmit) {
            errMsg = true;
            setContestDisabled(true)
            setIsLoader(false);
            setErr(`Please add user ${index + 1}`);
            setShowToast(true)
            return;
          }
          if (!euser.inNameGame) {
            errMsg = true;
            setContestDisabled(true)
            setIsLoader(false);
            setErr(`Please enter in game name for user ${index + 1}`);
            setShowToast(true)
            return;
          }
        }
      }
    });

    if (errMsg) {
      return false;
    } else {

      const blankCreationOriginItems = frmInitialValues.filter(item => item.creationOrigin === "" && item.phoneNo != "");

      let errMsgUpdateCreationOrigin = false;

      if (blankCreationOriginItems?.length > 0) {
        for (let item of blankCreationOriginItems) {
          try {
            const response = await updateCreationOriginApi(item);
            if (response?.success) {
              setFrmInitialValues(prevState =>
                prevState.map(obj =>
                  obj.phoneNo === item.phoneNo
                    ? { ...obj, creationOrigin: "GAMERJI" }
                    : obj
                )
              );
              handleUpdateTeam();
            }
            else {
              setIsLoader(false);
              setContestDisabled(true)
              errMsgUpdateCreationOrigin = true;
              setErr(response?.data?.errors?.[0]?.msg);
              setShowToast(true)
              return;
            }
          } catch (error) {
            setIsLoader(false);
            setContestDisabled(true)
            errMsgUpdateCreationOrigin = true;
            setErr(error);
            setShowToast(true)
            return;
          }
        }
      }
      else {
        handleUpdateTeam();
      }
    }
  }

  const handleUpdateTeam = async () => {
    setIsLoader(true);
    let saveTeamAry = []
    frmInitialValues.forEach((euser, index) => {
      if (euser?.phoneNo && euser?.userId) {
        saveTeamAry.push({
          "loginId": euser?.phoneNo,
          "countryCode": euser?.countryCode,
          "userVia": euser?.type === "phoneNo" ? "M" : "E",
          "user_id": euser?.userId,
          "ign": euser?.inNameGame,
          "klashUserId": euser?.klashUserId
        })
      }
    })

    let saveTeamObj = {
      "_id": JSON?.parse(localStorage?.getItem('profile'))?._id,
      "game": localStorage?.getItem("game"),
      "gameType": registerDataInfo?.game_type,
      "teamName": teamName?.trim(),
      "captain": {
        "loginId": captainInfo?.phoneNo,
        "countryCode": captainInfo?.countryCode,
        "userVia": captainInfo?.type === "phoneNo" ? "M" : "E",
        "user_id": captainInfo?.user_id,
        "ign": captainIgn,
        "klashUserId": captainInfo?.userId
      },
      "team": saveTeamAry,
      "type": localStorage?.getItem("registerType"),
      "scrim": localStorage?.getItem("registerType") === "tournament" ? registerDataInfo?.event : registerDataInfo?.contest
    };

    if (teamId) {
      let createTeamRes = (await updateTeam(saveTeamObj, teamId))
      if (createTeamRes?.success) {
        //api call
        handleRegistration();
      }
      else {
        setIsLoader(false);
        setContestDisabled(true)
        setErr(createTeamRes?.data?.msg);
        setShowToast(true)
        return;
      }
    }
    else {
      let createTeamRes = (await createTeam(saveTeamObj))
      if (createTeamRes?.success) {
        //api call
        handleRegistration();
      }
      else {
        setIsLoader(false);
        setContestDisabled(true)
        setErr(createTeamRes?.data?.msg);
        setShowToast(true)
        return;
      }
    }
  }

  const handleRegistration = async () => {
    setIsLoader(true);
    let data = {};
    let saveTeamAry = []
    frmInitialValues.forEach((euser, index) => {
      if (euser?.phoneNo && euser?.userId) {
        saveTeamAry.push({
          gamerjiName: euser?.phoneNo,
          countryCode: euser?.countryCode,
          in_game_name: euser?.inNameGame,
          user_id: euser?.userId,
          klashUserId: euser?.klashUserId
        })
      }
    })

    if (localStorage?.getItem("registerType") === "tournament") {
      let registerData = registerDataInfo;

      data = {
        team: saveTeamAry,
        contest: registerData?.contest,
        isActive: registerData?.isActive,
        event: registerData?.event,
        type: registerData?.type,
        uniqueIGN: captainIgn,
        inGameUserName: captainIgn,
        teamName: teamName?.trim(),
        indigg_access_token: registerData?.indigg_access_token,
        currencyCode: registerData?.currencyCode,
        user: captainInfo?.user_id
      }

      let res = await onCreateTournament(data);
      if (res && res?.success === true) {
        setIsLoader(false);
        setContestDisabled(false)
        navigate("/tournament/details/" + registerData?.event);
      }
      else {
        setIsLoader(false);
        setContestDisabled(true)
        setErr(res?.data?.errors?.[0]?.msg);
        setShowToast(true)
      }
    }
    else {
      let registerData = registerDataInfo;

      data = {
        players_data: saveTeamAry,
        teamName: teamName?.trim(),
        joinedDate: registerData?.joinedDate,
        JoinedTimestamp: registerData?.JoinedTimestamp,
        contest: registerData?.contest,
        inGameUserName: captainIgn,
        user: captainInfo?.user_id,
        indigg_access_token: registerData?.indigg_access_token,
      };

      let res = await joinContestApi(data);
      if (res && res?.success === true) {
        setIsLoader(false);
        setContestDisabled(false)
        navigate("/contest/details/" + registerData?.contest);
      }
      else {
        setIsLoader(false);
        setContestDisabled(true)
        setErr(res?.data?.errors?.[0]?.msg);
        setShowToast(true)
      }
    }
  }

  // Assume this is your API call function
  const updateCreationOriginApi = async (user) => {
    setIsLoader(true);
    try {
      let payload = {
        "user_name": user?.phoneNo,
        "game": localStorage?.getItem('game'),
        "inGameUserName": user?.inNameGame
      }

      if (user?.type === "phoneNo") {
        payload.user_name = user?.countryCode + user?.phoneNo;
      }
      else {
        payload.user_name = user?.phoneNo;
      }

      let response = (await updateInGameNameByIdentifier(payload))
      if (response?.success) {
        setIsLoader(false);
        return response
      }
      else {
        setIsLoader(false);
        return response
      }
    } catch (error) {
      setIsLoader(false);
      return error
    }
  };

  return (

    <Header title="Team Registration" onBackClick={handleBackClick}>
      {isLoading ? <Loader /> : <></>}
      <Formik
        enableReinitialize={true}
        initialValues={frmInitialValues}
      >
        {({ errors, values }) => (
          <>
            <div className="teamregistration-team-name-container">
              <label htmlFor="team-name" className="teamregistration-team-name-label">
                Team Name:
              </label>
              <input
                type="text"
                id="team-name"
                className="teamregistration-team-name-input"
                placeholder="Enter Team Name"
                value={teamName?.trim()}
                minLength={1}
                maxLength={16}
                onChange={(e) => setTeamName(e?.target?.value?.trim())}
                required
              />
            </div>
            <div className="teamregistration-details">

              <Box className="tab-panel-box">
                {/* Superpower Section */}
                <div className={`superpower-container ${teamId && "active"}`}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    className="superpower-wapper"
                  >
                    <Grid item className="superpower-box">
                      <div className="teamregistration-superpower-title">
                        Captain: {captainInfo?.inNameGame}
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* Details Grid */}
                <div className="teamregistration-details-container">
                  <div className="teamregistration-phone-in-game-container">
                    <Grid container spacing={2} className="phone-in-game-grid">
                      <Grid item xs={12}>
                        <div className="teamregistration-phone-container">
                          <div className="teamregistration-phone-input-wrapper">
                            <select className="teamregistration-phone-country-code" disabled>
                              <option value="+91">+91</option>

                            </select>
                            <input
                              type="text"
                              id="phone-number"
                              className="teamregistration-phone-input"
                              placeholder="Enter Mobile Number"
                              value={captainInfo?.phoneNo}
                              readOnly
                            />
                          </div>
                        </div>
                      </Grid>


                      <Grid item xs={12}>
                        <div className="teamregistration-in-game-name-container">
                          <label
                            htmlFor="in-game-name"
                            className="teamregistration-in-game-name-label"
                          >
                            In Game Name:
                          </label>
                          <input
                            type="text"
                            id="in-game-name"
                            className="teamregistration-in-game-name-input"
                            placeholder="Enter In Game Name"
                            value={captainIgn}
                            readOnly
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {/* roomid pass */}
              </Box>
              {frmInitialValues?.length > 0 &&
                frmInitialValues?.map((ele, index) => {
                  const cardId = `card-${index}`;
                  const activeTabs = activeTab[cardId] || "Mobile";

                  return (
                    <Box className="tab-panel-box">
                      {/* Superpower Section */}
                      <div className="row col-12 superpower-container">
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          className="superpower-wapper"
                        >
                          <div item className="col-4 superpower-box">
                            <div className="superpower-title">Player {index + 2}</div>
                            {/* {ele?.isOptional && (<p className="mb-0 text-gray" style={{fontSize:'0.8rem'}}>Optional</p>)} */}
                          </div>
                          <div className="row col-8">
                            <RadioGroup
                              value={selectedOption}
                              onChange={handleChange}
                              row
                              aria-label="options"
                              sx={{ marginRight: "0px" }}
                            >
                              <Grid
                                item
                                textAlign="center"
                                className="col-6 superpower-box superpower-box-1"
                              >
                                <FormControlLabel
                                  name={`flexRadioDefault${index + 'm'}`}
                                  id={`flexRadioDefault${index + 'm'}`}
                                  disabled={disabledCards.includes(index)}
                                  checked={activeTabs === "Mobile"}
                                  value="Mobile"
                                  onChange={(e) => {
                                    setErr('')
                                    setShowToast(false)

                                    let formdata = [...frmInitialValues];
                                    let fromdataobj = {
                                      ...formdata[index],
                                    };
                                    fromdataobj.type = 'phoneNo';
                                    fromdataobj.phoneNo = '';
                                    formdata[index] = fromdataobj;
                                    setFrmInitialValues(formdata);
                                    setMobileNo()
                                    // setActiveTab(index+'m')}
                                    setActiveTab((prevTabs) => ({
                                      ...prevTabs,
                                      [cardId]: e.target.value,
                                    }));
                                  }}
                                  control={<Radio className="custom-radio" />}
                                  label={
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="completed-button"
                                      disableElevation

                                    >
                                      Via Mobile
                                    </Button>
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                textAlign="center"
                                className="col-6 superpower-box superpower-box-1"
                              >
                                <FormControlLabel
                                  name={`flexRadioDefault${index + 'e'}`}
                                  id={`flexRadioDefault${index + 'e'}`}
                                  disabled={disabledCards.includes(index)}
                                  value="Email"
                                  checked={activeTabs === "Email"}
                                  onChange={(e) => {
                                    setErr('')
                                    setShowToast(false)

                                    let formdata = [...frmInitialValues];
                                    let fromdataobj = {
                                      ...formdata[index],
                                    };
                                    fromdataobj.type = 'email';
                                    fromdataobj.phoneNo = '';
                                    formdata[index] = fromdataobj;
                                    setFrmInitialValues(formdata);
                                    setMobileNo('')

                                    // setActiveTab(index+'e')

                                    setActiveTab((prevTabs) => ({
                                      ...prevTabs,
                                      [cardId]: e.target.value,
                                    }));

                                  }}
                                  control={<Radio className="custom-radio" id={`RadioDefault${index + 'e'}`} />}
                                  label={
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="completed-button"
                                      disableElevation
                                    >
                                      Via Email
                                    </Button>
                                  }
                                />
                              </Grid>
                            </RadioGroup>
                          </div>

                        </Grid>
                      </div>

                      {/* Details Grid */}
                      <div className="teamregistration-details-container">
                        <div className="teamregistration-phone-in-game-container">
                          <Grid container spacing={2} className="phone-in-game-grid">
                            <Grid item xs={12}>
                              <div className="teamregistration-phone-container">
                                <div className="teamregistration-phone-input-wrapper">
                                  {activeTabs === "Email" ? (
                                    <span className="emailteamreg">Email:</span>
                                  )
                                    :
                                    (
                                      <select className="teamregistration-phone-country-code" disabled>
                                        <option value="+91">+91</option>
                                      </select>
                                    )
                                  }
                                  {activeTabs === "Email" ? (
                                    <div className="team-invitaion-btn">
                                      <input
                                        type="email"
                                        id="phone-number"
                                        className="teamregistration-phone-input"
                                        placeholder="Enter Email Id"
                                        value={frmInitialValues[index]?.phoneNo}
                                        readOnly={ele?.isSubmit && true}
                                        onChange={(e) => {
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.phoneNo = e.target.value;
                                          if (ele?.buttonStatus === 2 || ele?.buttonStatus === 3 || e.target.value === "") {
                                            fromdataobj.inNameGame = "";
                                            fromdataobj.isSubmit = false;
                                            fromdataobj.userId = "";
                                            fromdataobj.creationOrigin = "";
                                            fromdataobj.buttonStatus = 0;
                                            fromdataobj.isInGameNameExists = false;
                                            fromdataobj.klashUserId = "";
                                            formdata[index] = fromdataobj;
                                          }
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                        }}
                                      />
                                      {ele?.buttonStatus === 0 && (
                                        <button
                                          className={frmInitialValues[index]?.phoneNo?.length > 0 ? `invitebtn` : `invitebtn-before`}
                                          type="button"
                                          onClick={() => handleUsrBtn(index)}
                                        >
                                          ADD
                                        </button>
                                      )}
                                      {ele?.buttonStatus === 1 && (
                                        <button
                                          className="invitebtn-remove"
                                          type="button"
                                          onClick={() => handleUsrBtnRemove(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                      {ele?.buttonStatus === 2 && (
                                        <RWebShare
                                          data={localStorage?.getItem("registerType") === "tournament" ?
                                            shareLink(localStorage?.getItem('game'), null, null, registerDataInfo?.contest, 'tournament')
                                            : shareLink(localStorage?.getItem('game'), registerDataInfo?.game_type, localStorage?.getItem('selectedGameMode'), registerDataInfo?.event, 'contest')}
                                          // data={{
                                          //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
                                          //   url:
                                          //  localStorage?.getItem("registerType") === "tournament"?
                                          //  `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&type=tournament`
                                          //  : `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&gameType=${registerDataInfo?.game_type}&gameTypeName=${localStorage?.getItem('selectedGameMode')}&type=contest`
                                          // }}
                                          sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                                          onClick={() => {
                                            let formdata = [...frmInitialValues];
                                            let fromdataobj = { ...formdata[index] };
                                            fromdataobj.inNameGame = "";
                                            fromdataobj.isSubmit = false;
                                            fromdataobj.userId = "";
                                            fromdataobj.creationOrigin = "";
                                            fromdataobj.buttonStatus = 3;
                                            fromdataobj.isInGameNameExists = false;
                                            formdata[index] = fromdataobj;
                                            setFrmInitialValues(formdata);
                                          }}
                                        >
                                          <button
                                            className={`invitebtn`}
                                            type="button"
                                            onClick={() => {
                                              // let formdata = [...frmInitialValues];
                                              // let fromdataobj = { ...formdata[index] };
                                              // fromdataobj.inNameGame = "";
                                              // fromdataobj.isSubmit = false;
                                              // fromdataobj.userId = "";
                                              // fromdataobj.creationOrigin = "";
                                              // fromdataobj.buttonStatus = 3;
                                              // fromdataobj.isInGameNameExists = false;
                                              // formdata[index] = fromdataobj;
                                              // setFrmInitialValues(formdata);
                                              console.log("clicked")
                                            }}
                                          >
                                            INVITE
                                          </button>
                                        </RWebShare>
                                      )}
                                      {ele?.buttonStatus === 3 && (
                                        <button
                                          className={`invitebtn-before`}
                                          type="button"
                                          onClick={() => console.log()}
                                        >
                                          INVITED
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="team-invitaion-btn">
                                      <input
                                        type="text"
                                        id="phone-number"
                                        className="teamregistration-phone-input"
                                        placeholder="Enter Mobile Number"
                                        readOnly={ele?.isSubmit && true}
                                        onKeyDown={(e) => {
                                          // Allow digits (0-9), '+', and control keys like backspace, arrow keys, etc.
                                          if (
                                            !/[0-9+]/.test(e.key) &&    // Check for digits and plus sign
                                            e.key !== "Backspace" &&     // Allow backspace
                                            e.key !== "ArrowLeft" &&     // Allow arrow keys
                                            e.key !== "ArrowRight" &&    // Allow arrow keys
                                            e.key !== "Tab" &&           // Allow tab
                                            e.key !== "Delete" &&        // Allow delete
                                            e.key !== "Enter" &&         // Allow enter
                                            e.key !== "End" &&         // Allow end
                                            e.key !== "Home"            // Allow home
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        value={frmInitialValues[
                                          index
                                        ]?.phoneNo.slice(0, 14)}
                                        onChange={(e) => {
                                          const digitOnlyRegex = /^\d*$/;
                                          if (!digitOnlyRegex.test(e.target.value)) {
                                            setErr("Please enter valid mobile no");
                                            setShowToast(true)
                                            return
                                          }
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.phoneNo = e.target.value;
                                          if (ele?.buttonStatus === 2 || ele?.buttonStatus === 3 || e.target.value === "") {
                                            fromdataobj.inNameGame = "";
                                            fromdataobj.isSubmit = false;
                                            fromdataobj.userId = "";
                                            fromdataobj.creationOrigin = "";
                                            fromdataobj.buttonStatus = 0;
                                            fromdataobj.isInGameNameExists = false;
                                            fromdataobj.klashUserId = "";
                                            formdata[index] = fromdataobj;
                                          }
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                        }}
                                      />
                                      {ele?.buttonStatus === 0 && (
                                        <button
                                          className={frmInitialValues[index]?.phoneNo?.length > 0 ? `invitebtn` : `invitebtn-before`}
                                          type="button"
                                          onClick={() => handleUsrBtn(index)}
                                        >
                                          ADD
                                        </button>
                                      )}
                                      {ele?.buttonStatus === 1 && (
                                        <button
                                          className="invitebtn-remove"
                                          type="button"
                                          onClick={() => handleUsrBtnRemove(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                      {ele?.buttonStatus === 2 && (
                                        <RWebShare
                                          data={localStorage?.getItem("registerType") === "tournament" ?
                                            shareLink(localStorage?.getItem('game'), null, null, registerDataInfo?.contest, 'tournament')
                                            : shareLink(localStorage?.getItem('game'), registerDataInfo?.game_type, localStorage?.getItem('selectedGameMode'), registerDataInfo?.event, 'contest')}
                                          // data={{
                                          //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
                                          //   url:
                                          //  localStorage?.getItem("registerType") === "tournament"?
                                          //  `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&type=tournament`
                                          //  : `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&gameType=${registerDataInfo?.game_type}&gameTypeName=${localStorage?.getItem('selectedGameMode')}&type=contest`
                                          // }}
                                          sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                                          onClick={() => {
                                            let formdata = [...frmInitialValues];
                                            let fromdataobj = { ...formdata[index] };
                                            fromdataobj.inNameGame = "";
                                            fromdataobj.isSubmit = false;
                                            fromdataobj.userId = "";
                                            fromdataobj.creationOrigin = "";
                                            fromdataobj.buttonStatus = 3;
                                            fromdataobj.isInGameNameExists = false;
                                            formdata[index] = fromdataobj;
                                            setFrmInitialValues(formdata);
                                          }}
                                        >
                                          <button
                                            className={`invitebtn`}
                                            type="button"
                                            onClick={() => {
                                              // let formdata = [...frmInitialValues];
                                              // let fromdataobj = { ...formdata[index] };
                                              // fromdataobj.inNameGame = "";
                                              // fromdataobj.isSubmit = false;
                                              // fromdataobj.userId = "";
                                              // fromdataobj.creationOrigin = "";
                                              // fromdataobj.buttonStatus = 3;
                                              // fromdataobj.isInGameNameExists = false;
                                              // formdata[index] = fromdataobj;
                                              // setFrmInitialValues(formdata);
                                              console.log("clicked")
                                            }}
                                          >
                                            INVITE
                                          </button>
                                        </RWebShare>
                                      )}
                                      {ele?.buttonStatus === 3 && (
                                        <button
                                          className={`invitebtn-before`}
                                          type="button"
                                          onClick={() => console.log()}
                                        >
                                          INVITED
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <div className="teamregistration-in-game-name-container">
                                <label
                                  htmlFor="in-game-name"
                                  className="teamregistration-in-game-name-label"
                                >
                                  In Game Name:
                                </label>
                                <input
                                  type="text"
                                  id="in-game-name"
                                  className="teamregistration-in-game-name-input"
                                  placeholder="Enter In Game Name"
                                  value={frmInitialValues[
                                    index
                                  ]?.inNameGame}
                                  onChange={(e) => {
                                    let formdata = [...frmInitialValues];
                                    let fromdataobj = {
                                      ...formdata[index],
                                    };
                                    fromdataobj.inNameGame = e.target.value;
                                    formdata[index] = fromdataobj;
                                    setFrmInitialValues(formdata);
                                  }}
                                  readOnly={ele?.isInGameNameExists}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      {/* roomid pass */}
                    </Box>
                  )
                }
                )}
            </div>

            {showToast && <ToastPopup message={err} setShowToast={setShowToast} />}
            <button type="button" className={contestDisabled ? `save-team-btn active` : `save-team-btn`} disabled={!contestDisabled} onClick={(e) => handleSubmit(e)}>SAVE TEAM</button>
          </>
        )}

      </Formik>
    </Header>
  );
};

export default Teamregistration;
